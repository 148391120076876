import * as React from 'react'
import { SVGProps } from 'react'

const SvgDecorativeTriangle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.46 9 12 11.177 14.54 9 12 6.823 9.46 9Zm2.946-3.474 3.5 3 3.5 3v.949l-3.5 3-3.5 3h-.813l-3.5-3-3.5-3v-.95l3.5-3 3.5-3h.813ZM8.5 14.176 11.04 12 8.5 9.823 5.96 12l2.54 2.177ZM12.96 12l2.54 2.177L18.04 12 15.5 9.823 12.96 12Zm-3.5 3L12 17.177 14.54 15 12 12.823 9.46 15Z"
      fill={props.color}
    />
  </svg>
)

export default SvgDecorativeTriangle

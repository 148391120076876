import * as React from 'react'
import { SVGProps } from 'react'

const SvgPassed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.625 8a5.375 5.375 0 1 1 10.75 0 5.375 5.375 0 0 1-10.75 0ZM8 1.375a6.625 6.625 0 1 0 0 13.25 6.625 6.625 0 0 0 0-13.25ZM8 11.75h-.024l-.75-.005.01-1.5.75.005H8.014l.75-.005.01 1.5-.75.005H8Zm2.634-1.081-.533.527-1.054-1.068.534-.527.02-.02.527-.534 1.068 1.054-.527.533a1.827 1.827 0 0 1-.035.035ZM6.42 9.6l.534.527-1.054 1.068-.534-.527a2.492 2.492 0 0 1-.034-.035l-.527-.533 1.068-1.054.527.534.02.02ZM5.75 8.015l.005.75-1.5.01-.005-.75v-.049l.005-.75 1.5.01-.005.75V8.014Zm6 .01-.005.75-1.5-.01.005-.75v-.03l-.005-.75 1.5-.01.005.75v.049Zm-1.081-2.66.527.534-1.068 1.054-.527-.534-.02-.02-.534-.527 1.054-1.068.533.527.035.034ZM6.399 6.42l-.527.534-1.068-1.054.527-.534a4.06 4.06 0 0 1 .034-.034l.534-.527 1.054 1.068-.534.527-.02.02Zm1.586-.669-.75.005-.01-1.5.75-.005h.049l.75.005-.01 1.5-.75-.005H7.986Z"
      fill="currentColor"
      fillOpacity={0.9}
    />
  </svg>
)

export default SvgPassed

import { WasmExtension } from '@cosmjs/cosmwasm-stargate'
import { QueryClient } from '@cosmjs/stargate'
import { QueryContractsByCodeResponse } from 'cosmjs-types/cosmwasm/wasm/v1/query'
import { selectorFamily } from 'recoil'

import { cosmWasmClientSelector } from '@dao-dao/state'

export const contractsByCodeId = selectorFamily({
  key: 'contractsByCodeId',
  get:
    (codeId: number) =>
    async ({ get }) => {
      const client = get(cosmWasmClientSelector)
      if (!client) {
        return []
      }
      let contracts = await client.getContracts(codeId)
      return contracts
    },
})

export const allContractsByCodeId = selectorFamily({
  key: 'contractsByCodeId',
  get:
    (codeId: number) =>
    async ({ get }) => {
      const client = get(cosmWasmClientSelector)
      if (!client) {
        return []
      }

      const queryClient: QueryClient & WasmExtension = (
        client as any
      ).forceGetQueryClient()

      const all: string[] = []

      try {
        let startAtKey: Uint8Array | undefined = undefined
        do {
          const response: QueryContractsByCodeResponse =
            await queryClient.wasm.listContractsByCodeId(codeId, startAtKey)
          const { contracts, pagination } = response

          all.unshift(...contracts)
          startAtKey = pagination?.nextKey
        } while (startAtKey?.length !== 0)
      } catch (_e: any) {
        return get(contractsByCodeId(codeId))
      }

      return all
    },
})

export const blockHeightTimestampSafeSelector = selectorFamily<
  Date | undefined,
  number
>({
  key: 'blockHeightTimestampSafe',
  get:
    (blockHeight) =>
    async ({ get }) => {
      const client = get(cosmWasmClientSelector)
      if (!client) {
        return undefined
      }

      try {
        const block = await client.getBlock(blockHeight)
        return new Date(Date.parse(block.header.time))
      } catch (error) {
        console.error(error)
      }
    },
})

export const contractInstantiateTime = selectorFamily<Date | undefined, string>(
  {
    key: 'contractInstantiateTimeSelector',
    get:
      (address: string) =>
      async ({ get }) => {
        const client = get(cosmWasmClientSelector)
        if (!client) {
          return undefined
        }

        const events = await client.searchTx([
          { key: 'instantiate._contract_address', value: address },
        ])
        if (events.length == 0) {
          // Failed to locate the instantiate transaction. This happens if the
          // RPC node doesn't have historical data this far back.
          return undefined
        }
        // The timestamp field is available when running this query via the
        // command line but is not available from CosmJS so we need to run a
        // second query to get the block info.
        const height = events[0].height

        return get(blockHeightTimestampSafeSelector(height))
      },
  }
)

interface IPagedContractsByCodeId {
  contracts: string[]
  total: number
}

export const pagedContractsByCodeId = selectorFamily<
  IPagedContractsByCodeId,
  { codeId: number; page: number; limit: number }
>({
  key: 'pagedContractsByCodeId',
  get:
    ({ codeId, page, limit }) =>
    async ({ get }) => {
      const allContracts = get(allContractsByCodeId(codeId))
      const total = allContracts.length
      const offset = (page - 1) * limit
      let contracts: string[]

      if (offset > allContracts.length) {
        contracts = []
      } else if (page * limit > allContracts.length) {
        contracts = allContracts.slice(offset, allContracts.length)
      } else {
        contracts = allContracts.slice(offset, limit + offset)
      }

      return { contracts, total } as IPagedContractsByCodeId
    },
})

export const contractAdminSelector = selectorFamily<string | undefined, string>(
  {
    key: 'contractAdminSelector',
    get:
      (address: string) =>
      async ({ get }) => {
        const client = get(cosmWasmClientSelector)
        if (!client) {
          return undefined
        }

        try {
          const contract = await client.getContract(address)
          return contract.admin
        } catch (_) {
          return undefined
        }
      },
  }
)

import * as React from 'react'
import { SVGProps } from 'react'

const SvgAbstain = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10.186 14h-3.79a2.329 2.329 0 0 1-1.672-.718L.5 8.842l1.198-1.069a1.15 1.15 0 0 1 1.307-.157l1.395.747V2.79A1.453 1.453 0 0 1 6.14 1.36 1.453 1.453 0 0 1 7.582 0c.498 0 .932.25 1.192.636a1.453 1.453 0 0 1 1.99 1.354v.163a1.453 1.453 0 0 1 1.736 1.43v8.083A2.324 2.324 0 0 1 10.186 14Zm-8.02-5.089 3.39 3.56c.22.228.522.362.834.362h3.796c.636 0 1.157-.525 1.157-1.167V3.583a.288.288 0 0 0-.29-.292.288.288 0 0 0-.289.292v3.414H9.607V1.99a.288.288 0 0 0-.29-.292.288.288 0 0 0-.289.292v5.007H7.871V1.46a.288.288 0 0 0-.289-.292.288.288 0 0 0-.29.292v5.538H6.136V2.79a.288.288 0 0 0-.289-.292.291.291 0 0 0-.29.291V10.3L2.462 8.65l-.295.262Z" />
  </svg>
)

export default SvgAbstain
